import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ROUTES} from "../helpers/routing";
import {useAppDispatch, useTypedSelector} from "../store/store";
import AppContainer from "../components/appContainer";
import {Alert, AlertTitle, Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import {checkRecaptchaToken, completeRegistration} from "../api/api";
import {mapAnswersToMenuPreferences} from "../helpers/menuPreferences";
import {sendCompleteRegistration} from "../helpers/analytics";
import {AccountCircle, EmailRounded} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setToken} from "../store/accountSlice";

interface ButtonBuyProps {
    isLoading: boolean;
}

const NextButton: React.FC<ButtonBuyProps> = ({isLoading}) => {
    if (isLoading) {
        return (
            <Stack direction="row" justifyContent="center" width="100%"><Box component="div" className="dots-flashing"/></Stack>);
    }

    return (
        <Button variant="contained"
                color="secondary"
                sx={{
                    color: 'white',
                    textTransform: 'none',
                    borderRadius: 4
                }}
                size="large"
                type="submit">
            Далее
        </Button>
    );
};

const RegistrationCompleteForm = () => {
    const quizState = useTypedSelector((s) => s.quiz);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBot, setIsBot] = useState(false);

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const action = "WQ_registrationCompleteForm";
        const token = await executeRecaptcha(action);
        setIsBot(await checkRecaptchaToken(token, action));
    };

    const handleSubmit = async ({name, email}: { name: string; email: string }) => {
        setIsLoading(true);
        setHasError(false);
        await handleReCaptchaVerify();

        const registrationResult = await completeRegistration(
            {
                menuPreferences: mapAnswersToMenuPreferences(quizState.answers),
                name,
                email
            }
        );
        if (registrationResult === null || !registrationResult?.success) {
            setIsLoading(false);
            setHasError(true);
            return;
        }

        setHasError(false);
        sendCompleteRegistration(isBot);
        dispatch(
            setToken({
                authToken: registrationResult!.result!.accessToken
            })
        );

        navigate(ROUTES.LOADING_PLAN);
    };

    return (
        <Stack
            spacing={2}
            component="form"
            onSubmit={async (event: any) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const email = formJson.email as string;
                const name = formJson.name as string;
                await handleSubmit({name, email});
            }}>

            {hasError &&
                <Alert variant="filled" severity="error">
                    <AlertTitle>Упс... Что-то пошло не так!</AlertTitle>
                    Попробуйте повторить попытку еще раз.
                </Alert>
            }
            <TextField
                autoFocus={true}
                required
                margin="dense"
                id="name2"
                name="name"
                type="text"
                fullWidth
                disabled={isLoading}
                placeholder="Ваше имя *"
                helperText="Нужно для обращения в письмах и приложении."
                variant="outlined"
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                            <AccountCircle/>
                        </InputAdornment>
                    ),
                    sx: {
                        borderRadius: 3
                    }
                }}
            />
            <TextField
                required
                margin="dense"
                id="name"
                name="email"
                disabled={isLoading}
                placeholder="Ваш email *"
                type="email"
                fullWidth
                helperText="Этот Email будет использоваться для авторизации."
                variant="outlined"
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                            <EmailRounded/>
                        </InputAdornment>
                    ),
                    sx: {
                        borderRadius: 3
                    }
                }}
            />

            <Box mt={3}/>
            <NextButton isLoading={isLoading}/>
            <Box mt={2}/>
        </Stack>
    );
};

const RegisterAccountPage = () => {
    const accountState = useTypedSelector(state => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (!!accountState.authToken) {
            navigate(ROUTES.LOADING_PLAN);
        }
    }, [accountState, navigate]);

    return (
        <AppContainer>
            <Typography variant="h4" component="div" mt={2}>
                Всё почти готово
            </Typography>

            <Typography variant="body2" component="p" mt={1} fontSize="16px">
                Укажите своё имя и свой email, чтобы завершить регистрацию.
            </Typography>

            <Box mt={5}/>
            <RegistrationCompleteForm/>
        </AppContainer>
    );
};

export default RegisterAccountPage;