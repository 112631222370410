import {toPascalCase} from "js-convert-case";

export const convertKeysToPascalCase = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToPascalCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const newKey = toPascalCase(key);
            acc[newKey] = convertKeysToPascalCase(obj[key]);
            return acc;
        }, {} as any);
    }
    return obj;
};