import Grid2 from "@mui/material/Unstable_Grid2";
import {Avatar, Button, Chip, Paper, Typography} from "@mui/material";
import React, {useEffect, useState, FC} from "react";
import {AnswerType, QuestionOption} from "../../../store/quizSlice";

interface QuestionChipProps {
    option: QuestionOption;
    isActive: boolean;
    onClick: (name: string) => void;
}

const QuestionChip: FC<QuestionChipProps> = ({option, isActive, onClick}) => {
    const textColor = isActive ? 'white' : 'primary';
    const chipColor = isActive ? 'secondary' : 'default';

    return (
        <Grid2 xs="auto">
            <Chip variant={isActive ? 'filled' : 'outlined'}
                  label={<Typography fontSize="16px" color={textColor}>{option.text}</Typography>}
                  avatar={<Avatar src={option.icon}/>}
                  color={chipColor}
                  onClick={() => onClick(option.name)}
                  clickable/>
        </Grid2>
    );
};

interface NextButtonProps {
    onClick: () => void;
}

const NextButton: FC<NextButtonProps> = ({onClick}) => {
    return (
        <Paper elevation={1}
               sx={{
                   position: 'fixed',
                   bottom: 0,
                   zIndex: 999,
                   right: 0,
                   width: '100%'
               }}>
            <Button variant="contained"
                    color="secondary"
                    sx={{
                        color: 'white',
                        mb: 2,
                        mt: 2,
                        minWidth: '328px',
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={onClick}
                    size="large">
                Далее
            </Button>
        </Paper>
    );
};

interface ChipsQuestionProps {
    chips: QuestionOption[];
    initialAnswer?: AnswerType;
    onNextClick: (answer: string[]) => void;
}

export const ChipsQuestion: FC<ChipsQuestionProps> = ({chips, initialAnswer, onNextClick}) => {
    const [answer, setAnswer] = useState<string[]>(initialAnswer as string[] || []);

    useEffect(() => {
        setAnswer(initialAnswer as string[] || []);
    }, [initialAnswer]);

    const handleChipClick = (name: string) => {
        const newAnswer: string[] = answer
            ? answer.some(a => a === name)
                ? answer.filter(a => a !== name)
                : [...answer, name]
            : [name];

        setAnswer(newAnswer);
    };

    const handleOnNext = () => {
        onNextClick(answer);
        setAnswer([]);
    };

    return (
        <>
            <Grid2 container spacing={1} justifyContent="center">
                {chips.map(c => (
                    <QuestionChip option={c}
                                  key={c.name}
                                  isActive={answer && answer.some(a => a === c.name)}
                                  onClick={handleChipClick}
                    />))}
            </Grid2>
            <NextButton onClick={handleOnNext}/>
        </>
    );
};