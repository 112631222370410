import {useEffect, useState, FC} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../helpers/routing";
import AppContainer from "../components/appContainer";

interface CircularProgressFilledProps {
    onFinishProgress: () => void;
}

const CircularProgressFilled: FC<CircularProgressFilledProps> = ({onFinishProgress}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration: number = 6000;
        const intervalTime: number = 100;
        const totalSteps: number = duration / intervalTime;
        const step: number = 100 / totalSteps;

        let currentProgress: number = 0;
        const interval: NodeJS.Timeout = setInterval(() => {
            currentProgress += step;
            setProgress(Math.min(currentProgress, 100));

            if (currentProgress >= 100) {
                onFinishProgress();
                clearInterval(interval);
            }
        }, intervalTime);

        return () => clearInterval(interval);
    }, [onFinishProgress]);

    return (
        <Box sx={{
            position: 'relative'
        }}>
            <CircularProgress color="secondary" variant="determinate" value={progress} size="150px"/>
            <Typography variant="h6" fontSize="26pt"
                        sx={{
                            position: 'absolute',
                            bottom: '33%',
                            left: '25%'
                        }}>
                {`${Math.round(progress)}%`}
            </Typography>
        </Box>
    );
};

const LoadingPlanPage: FC = () => {
    const navigate = useNavigate();
    const handleOnFinishProgress = () => {
        setTimeout(() => navigate(ROUTES.ORDER_PLAN), 500);
    };

    return (
        <AppContainer disableFooter>
            <Stack direction="column" mt={10}>
                <Stack direction="column"
                       alignItems="center"
                       spacing={2}
                       sx={{
                           margin: 'auto'
                       }}>
                    <Typography variant="h4">
                        Создаем ваше меню
                    </Typography>

                    <CircularProgressFilled onFinishProgress={handleOnFinishProgress}/>
                </Stack>
            </Stack>
        </AppContainer>
    );
};

export default LoadingPlanPage;