export enum IngredientCategory {
    Legumes,
    Mushrooms,
    Honey,
    Dairy,
    Meat,
    WalnutAndSeeds,
    Fish,
    Eggs
}

export enum MeatPreferences {
    Chicken,
    Pork,
    Beef,
    Turkey,
    Mutton,
    Duck,
    Rabbit
}

export enum CerealPreferences {
    Potato,
    Rice,
    Buckwheat,
    Pasta,
    Lentils,
    PearlBarley
}

export enum FishPreferences {
    Mackerel,
    Herring,
    PinkSalmon,
    Pollock,
    Trout,
    Salmon,
    Capelin,
    Shrimps
}

export enum SpicyPreferences {
    BayLeaf,
    Dill,
    KhmeliSuneli,
    Turmeric,
    Paprika,
    Basil,
    Curry,
    Ginger
}

export interface IngredientsPreferences {
    meatPreferences: MeatPreferences[];
    cerealPreferences: CerealPreferences[];
    fishPreferences: FishPreferences[];
    spicyPreferences: SpicyPreferences[];
}

export enum DayActivityLevel {
    VeryLow,
    Low,
    Medium,
    High,
    VeryHigh
}

export enum NutrientGoal {
    DropWeight,
    MaintainWeight,
    GainWeight
}

export interface NutrientCalcConfig {
    age: number;
    sex: Sex;
    height: number;
    weight: number;
    activityLevel: DayActivityLevel;
    goal: NutrientGoal;
}

export interface NutrientPreferences {
    calcConfig?: NutrientCalcConfig;
    kcal: number;
    proteinsPercentOfDv: number;
    fatsPercentOfDv: number;
    carbsPercentOfDv: number;
}

export enum MealType {
    Breakfast,
    Lunch,
    Dinner,
    Snack
}

export interface MealTypePreferences {
    mealType: MealType;
}

export enum Difficulty {
    Easy,
    Medium,
    Hard,
    Unknown
}

export enum Frequency {
    Rarely,
    Sometimes,
    Often,
    Unknown
}

export interface MenuPreferences {
    excludedIngredientCategories: IngredientCategory[];
    ingredientsPreferences: IngredientsPreferences;
    nutrientPreferences?: NutrientPreferences;
    mealTypes: MealTypePreferences[];
    personsAdjustment: number;
    estimatedMinutesMax: number;
    difficultyMax: Difficulty;
    mealExperimentsFrequency: Frequency;
    ingredientsExperimentsFrequency: Frequency;
    daysWithRepetition: number;
}

export enum Sex {
    Male,
    Female
}

export enum PaymentStatus {
    Created,
    Initialized,
    Paid,
    Failed
}

export interface Payment {
    accountId: number;
    status: PaymentStatus;
    amount: number;
    currency: string;
    name: string;
    description: string;
    productEndAt: Date;
    createdAt: Date;
    updatedAt: Date;
    expiresAt?: Date;
}

export interface CompleteRegistrationParams {
    menuPreferences: MenuPreferences;
    name: string;
    email: string;
}

export interface Response<TResult> {
    success: boolean,
    errors: ResponseError[] | null,
    result: TResult | null
}

export interface ResponseError {
    code: string,
    userMessage: string,
    internalMessage: string
}

export interface JwtResult {
    accessToken: string;
}

export const OFFER_CODES = {
    MONTH_PROMO_299: "MONTHPROMO299",
    AB_APPWEEK_1: "AB_APPWEEK1"
}