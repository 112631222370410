import {configureStore} from '@reduxjs/toolkit';
import featuresSlice from './featuresSlice';
import quizSlice from "./quizSlice";
import accountSlice from "./accountSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const LOCAL_STORAGE_KEY_V2 = "ch_quiz_state-v2";

interface RootState {
    features: ReturnType<typeof featuresSlice>;
    quiz: ReturnType<typeof quizSlice>;
    account: ReturnType<typeof accountSlice>;
}

const saveToLocalStorage = (state: RootState) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(LOCAL_STORAGE_KEY_V2, serializedState);
    } catch (e) {
        console.log(e);
    }
};

const loadFromLocalStorage = (): RootState | undefined => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY_V2);
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState) as RootState;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

// Create the store with types
const store = configureStore({
    reducer: {
        features: featuresSlice,
        quiz: quizSlice,
        account: accountSlice
    },
    preloadedState: loadFromLocalStorage()
});

// Save state to localStorage on state changes
store.subscribe(() => saveToLocalStorage(store.getState() as RootState));

export type AppDispatch = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;


export default store;
