import Stack from "@mui/material/Stack";
import Item from "@mui/material/Stack";
import {IconButton, LinearProgress} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import React, {FC} from "react";

interface QuizHeaderProps {
    questionNum: number;
    questionCount: number;
    onBackClick: () => void;
}

export const QuizHeader: FC<QuizHeaderProps> = ({questionNum, questionCount, onBackClick}) => {
    return (
        <Stack spacing={2} direction="column">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack/>
                    </IconButton>
                </Item>
                <Item className="QuizHeader-brandLogo" href="https://cookhaus.guru" component="a">CookHaus</Item>
                <Item className="QuizHeader-counter">{questionNum}/{questionCount}</Item>
            </Stack>
            <LinearProgress variant="determinate" value={questionNum / questionCount * 100} color="secondary"/>
        </Stack>
    );
};