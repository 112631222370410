import axios from "axios";
import packageInfo from '../../package.json';

export const configureAxios = (): void => {
    axios.defaults.headers.common['X-WebQuiz-Version'] = packageInfo.version || 'undefined-version';
    axios.defaults.timeout = 60000;
};

export const configureAuth = (accessToken: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};