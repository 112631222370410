const YmTrackerId: number = 97935481;
const VkTrackerId: number = 3540779;

export const sendStartedQuiz = (): void => {
    ym(YmTrackerId, 'reachGoal', 'startedQuiz');

    //@ts-ignore
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'startedQuiz'});
};

export const sendShownQuestion = (questionNum: number): void => {
    ym(YmTrackerId, "params", {
        "showQuestion": questionNum
    });
};

export const sendEndedQuiz = (): void => {
    ym(YmTrackerId, 'reachGoal', 'endedQuiz');

    //@ts-ignore
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'endedQuiz'});
};

export const sendStartPayment = (): void => {
    ym(YmTrackerId, 'reachGoal', 'startPayment');

    //@ts-ignore
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'startPayment'});
}

export const sendDownloadApp = (): void => {
    ym(YmTrackerId, 'reachGoal', 'downloadApp');
}

export const sendCompleteRegistration = (isBot: boolean) => {
    if (isBot) {
        ym(YmTrackerId, 'params', {
            'registrationCompleteFromBot': 1
        });
    } else {
        ym(YmTrackerId, 'reachGoal', 'completeRegistration');
    }
};

export const sendRedirectPage = (to: string, from?: string): void => {
    ym(YmTrackerId, 'params', {
        'redirectPage': {
            'to': to,
            'from': from || ''
        }
    });
};

export const sendSelectedFeatures = (selectedFeatures: string[]): void => {
    ym(YmTrackerId, 'params', {
        'selectedFeatures': selectedFeatures
    });
}

export const sendSuccessfulPayment = (amount: number): void => {
    ym(YmTrackerId, 'reachGoal', 'successfulPayment', {
        order_price: amount,
        currency: "RUB"
    });
};