import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {configureAuth} from "../api/config";

export interface AccountState {
    authToken: string | null;
}

interface SetTokenPayload {
    authToken: string;
}

export const accountSlice = createSlice({
    name: 'quiz',
    initialState: {
        authToken: null
    } as AccountState,
    reducers: {
        setToken: (state: AccountState, action: PayloadAction<SetTokenPayload>) => {
            const {authToken} = action.payload;

            configureAuth(authToken);
            return {
                ...state,
                authToken: authToken
            };
        },
    },
});

export const {setToken} = accountSlice.actions;
export default accountSlice.reducer;