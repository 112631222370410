function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): {
    x: number;
    y: number
} {
    const angleInRadians: number = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag: string = endAngle - startAngle <= 180 ? "0" : "1";

    const d: string = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        "L", x, y,
        "Z"
    ].join(" ");

    return d;
}

function createSegment(svg: SVGSVGElement, x: number, y: number, radius: number, startAngle: number, endAngle: number, color: string): void {
    const path: SVGPathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", describeArc(x, y, radius, startAngle, endAngle));
    path.setAttribute("fill", color);
    svg.appendChild(path);
}

function CreateCircle(id: string): void {
    const svg: SVGSVGElement = document.getElementById(id) as any;
    const centerX: number = 50;
    const centerY: number = 50;
    const radius: number = 45;

    createSegment(svg, centerX, centerY, radius, 0, 54, '#F8E71C'); // Yellow segment
    createSegment(svg, centerX, centerY, radius, 54, 162, '#BD10E0'); // Purple segment
    createSegment(svg, centerX, centerY, radius, 162, 360, '#FF0080'); // Red segment

    const innerCircle: SVGCircleElement = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    innerCircle.setAttribute("cx", centerX.toString());
    innerCircle.setAttribute("cy", centerY.toString());
    innerCircle.setAttribute("r", "20");
    innerCircle.setAttribute("fill", "#FFFFFF");
    svg.appendChild(innerCircle);
}

export {CreateCircle};