import Stack from "@mui/material/Stack";
import {Box, Container} from "@mui/material";
import React from "react";
import Item from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2";

export interface AppContainerProps {
    children?: React.ReactNode;
    disableLogo?: boolean;
    disableFooter?: boolean;
}

const AppContainer = ({children, disableLogo, disableFooter}: AppContainerProps) => {
    return (
        <Container maxWidth="xs"
                   sx={{
                       p: 0
                   }}>
            <Stack direction="column" height="100vh">
                {!disableLogo &&
                    <Item className="QuizHeader-brandLogo" mt={2} href="https://cookhaus.guru"
                          component="a">CookHaus</Item>
                }

                <Box mt={2}
                     pl={2}
                     pr={2}
                     sx={{
                         flex: 1,
                         borderRadius: 0
                     }}>
                    {children}
                </Box>

                {!disableFooter && <ContactLinks/>}
            </Stack>
        </Container>
    )
};

const ContactLinks: React.FC = () => (
    <Grid2 container
           justifyContent="center"
           columnSpacing={1}
           rowSpacing={2}
           mb={1}
           sx={{
               '& a': {
                   color: '#97A2B0',
                   textDecoration: 'none'
               }
           }}>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/terms_of_use">Пользовательское соглашение</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/privacy_policy">Политика конфиденциальности</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>
        </Grid2>
    </Grid2>
);

export default AppContainer;