import {QuestionValidation} from "../store/quizSlice";

export const validateNumber = (number: number, range: QuestionValidation): string | null => {
    if (!Number.isInteger(number)) {
        return 'Введите целое число';
    }

    if (number > range.to || number < range.from) {
        return `Введите число от ${range.from} до ${range.to}`;
    }

    return null;
};