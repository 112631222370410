import axios from "axios";
import {CompleteRegistrationParams, JwtResult, Payment, Response} from "./types";
import {convertKeysToPascalCase} from "../helpers/caseConvertation";

export const completeRegistration = async (
    {
        menuPreferences,
        name,
        email
    }: CompleteRegistrationParams
): Promise<Response<JwtResult> | undefined> => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/WebQuiz/CompleteRegistration",
            convertKeysToPascalCase({menuPreferences, name, email})
        );

        if (response.status !== 200) {
            console.log(response.statusText);
            return undefined;
        }

        return response.data;
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export const createPayment = async (offerCode: string): Promise<Response<string> | undefined> => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/WebQuiz/CreatePayment",
            offerCode,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (response.status !== 200) {
            console.log('non success response create payment');
            return undefined;
        }

        return response.data;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

export const getPaymentStatus = async (paymentId: string, s: string): Promise<Payment | null> => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/WebQuiz/GetPaymentStatus?paymentId=${paymentId}&s=${s}`);
        if (response.status !== 200) {
            console.log('status code of get payment status not 200');
            return null;
        }

        return response.data as Payment;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const checkRecaptchaToken = async (token: string, action: string): Promise<boolean> => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + `/WebQuiz/CheckRecaptcha?token=${token}&action=${action}`
        );

        return response.status === 200 && (response.data as Response<any>).success;
    } catch (e) {
        console.log(e);
        return false;
    }
};