import {
    Alert, AlertTitle,
    Avatar, Box,
    Button, Checkbox, Divider, List,
    ListItem,
    ListItemAvatar,
    ListItemText, Paper, Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useEffect, useMemo, useState} from "react";
import {CheckCircleRounded, CircleOutlined} from "@mui/icons-material";
import {AndroidOption, androidOptions} from "../data/paywall";
import {isAndroidOS} from "../helpers/isAndroid";
import {sendStartPayment} from "../helpers/analytics";
import AppContainer from "../components/appContainer";
import {createPayment} from "../api/api";
import {OFFER_CODES} from "../api/types";
import {useTypedSelector} from "../store/store";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../helpers/routing";

interface FeatureProps {
    icon: string;
    text: React.ReactNode | string;
    isLast: boolean;
}

const Feature: React.FC<FeatureProps> = ({icon, text, isLast}) => (
    <>
        <ListItem>
            <ListItemAvatar>
                <Avatar variant="square" src={icon}/>
            </ListItemAvatar>
            <ListItemText
                primary={<Typography fontSize="18px">{text}</Typography>}/>
        </ListItem>
        {!isLast && <Divider component="li"/>}
    </>
);

const FeaturesList: React.FC = () => {
    const features: Array<{ icon: string; text: React.ReactNode | string }> = [
        {
            icon: "/assets/paywall/apple.png",
            text: "Поддерживайте здоровый образ жизни с готовым меню"
        },
        {
            icon: "/assets/paywall/hourglass.png",
            text: (<>Экономьте <b>время</b>: с меню вы всегда будете знать что приготовить</>)
        },
        {
            icon: "/assets/paywall/budget.png",
            text: (<>Экономьте <b>бюджет</b> на доставках из ресторанов и кафе, готовя дома вкусную и полезную еду</>)
        },
        {
            icon: "/assets/paywall/fear.png",
            text: "Забудьте про стресс при выборе блюд: меню подобрано под ваши вкусы и вам не придется перебирать сотни рецептов"
        },
        {
            icon: "/assets/shopping-list.png",
            text: "Не забывайте купить нужные продукты благодаря списку покупок к меню"
        },
        {
            icon: "/assets/paywall/food-waste.png",
            text: "Не выбрасывайте продукты: меню подобрано так, чтобы уменьшить пищевые отходы"
        }
    ];

    return (
        <List>
            {features
                .map((f, i, arr) => (
                    <Feature key={f.icon} icon={f.icon} text={f.text} isLast={arr.length - 1 === i}/>
                ))}
        </List>
    );
};

interface BenefitProps {
    text: string;
}

const Benefit: React.FC<BenefitProps> = ({text}) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar variant="square" src="/assets/paywall/checklist.png" sx={{width: '40px', height: '40px'}}/>
        </ListItemAvatar>
        <ListItemText
            primary={<Typography fontSize="18px">{text}</Typography>}/>
    </ListItem>
);

interface BenefitsListProps {
    isAndroid: boolean;
}

const BenefitsList: React.FC<BenefitsListProps> = ({isAndroid}) => {
    const benefits: Array<{ text: string; isAndroid: boolean }> = [
        {
            text: "Меню на каждый день с рецептами и подробными шагами приготовления",
            isAndroid: false
        },
        {
            text: "Список покупок к меню",
            isAndroid: false
        },
        {
            text: "Подбор альтернативных вариантов блюд для меню",
            isAndroid: true
        },
        {
            text: "Поиск блюд по ингредиентам, что есть у вас в холодильнике",
            isAndroid: true
        },
        {
            text: "Делитесь списком покупок с членами семьи",
            isAndroid: true
        },
    ];

    return (
        <List>
            {benefits.map((b) => (!b.isAndroid || isAndroid) && (
                <Benefit key={b.text} text={b.text}/>
            ))}
        </List>
    );
};

interface BuyButtonProps {
    offerCode: string;
    caption: string;
}

const BuyButton: React.FC<BuyButtonProps> = ({offerCode, caption}) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        const link = await createPayment(offerCode);
        if (link === null || !link?.success) {
            setIsLoading(false);
            setHasError(true);
            return;
        }

        setHasError(false);
        sendStartPayment();
        window.location.href = link.result!;
    };

    return (
        <Stack spacing={2} direction="column" alignItems="center">
            {hasError &&
                <Alert variant="filled" severity="error">
                    <AlertTitle>Упс... Что-то пошло не так!</AlertTitle>
                    Попробуйте повторить попытку еще раз.
                </Alert>
            }

            {!isLoading &&
                <Button variant="contained"
                        color="primary"
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: 4
                        }}
                        onClick={handleSubmit}
                        size="large"
                        fullWidth={true}>
                    {caption}
                </Button>
            }

            {isLoading &&
                <div className="dots-flashing"/>
            }
        </Stack>
    );
};

const MonthMenuBuyBlock: React.FC = () => {
    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Получите ваше меню на месяц за</Typography>
            <Typography fontWeight="bold" fontSize="32px" color="secondary" mt={2}>299 руб.</Typography>

            <Box mt={2}/>
            <BuyButton offerCode={OFFER_CODES.MONTH_PROMO_299} caption="Получить меню"/>
        </Paper>
    );
};

const AndroidAppButtonStartFor1Rub: React.FC = () => {
    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Начните пользоваться за</Typography>
            <Typography fontWeight="bold" fontSize="32px" color="secondary" mt={2}>1 руб.</Typography>

            <Box mt={2}/>
            <BuyButton offerCode={OFFER_CODES.AB_APPWEEK_1} caption="Начать"/>
        </Paper>
    );
};

const AndroidDownloadApp: React.FC = () => {
    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Завершите регистрацию и скачайте приложение, чтобы начать
                пользоваться</Typography>

            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        mt: 2,
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    size="large"
                    fullWidth={true}>
                Завершить регистрацию
            </Button>
        </Paper>
    );
};

interface AndroidAppBuyOptionProps {
    checked: boolean;
    onChecked: () => void;
    optionName: string;
    benefitPercent?: string;
    monthPrice: number;
    periodPrice?: number;
    crossedPrice?: number;
    isLast: boolean;
}

const AndroidAppBuyOption: React.FC<AndroidAppBuyOptionProps> = ({
                                                                     checked,
                                                                     onChecked,
                                                                     optionName,
                                                                     benefitPercent,
                                                                     monthPrice,
                                                                     periodPrice,
                                                                     crossedPrice,
                                                                     isLast
                                                                 }) => {
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center"
                   onClick={() => onChecked()}>
                <Stack direction="row">
                    <Checkbox checked={checked}
                              checkedIcon={<CheckCircleRounded/>}
                              icon={<CircleOutlined/>}
                              color="secondary"
                              onChange={() => onChecked()}/>
                    <Stack direction="column" spacing="4px">
                        <Stack direction="row" alignItems="center">
                            <Typography variant="body1" fontSize="18px">
                                {optionName}
                            </Typography>

                            {benefitPercent && <Box ml="4px">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        background: '#70b9be',
                                        color: 'white',
                                        borderRadius: 2,
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        padding: '2px'
                                    }}>
                                    {benefitPercent}
                                </Typography>
                            </Box>}
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            {crossedPrice && <Typography variant="body2"
                                                         sx={{
                                                             position: 'relative',
                                                             '&:after': {
                                                                 content: '""',
                                                                 width: '100%',
                                                                 height: '1px',
                                                                 backgroundColor: 'red',
                                                                 position: 'absolute',
                                                                 top: '50%',
                                                                 left: 0
                                                             }
                                                         }}>
                                {(+crossedPrice).toLocaleString()} ₽
                            </Typography>}

                            {!!periodPrice && <Typography variant="body2">
                                {(+periodPrice).toLocaleString()} ₽
                            </Typography>}
                        </Stack>
                    </Stack>
                </Stack>

                <Typography variant="body2" fontSize="18px">
                    {(+monthPrice).toLocaleString()} ₽ / мес.
                </Typography>
            </Stack>
            {!isLast && <Divider/>}
        </Stack>
    );
};

const AndroidAppBuyBlock: React.FC = () => {
    const [checkedOption, setCheckedOption] = useState<AndroidOption>(androidOptions[0]);

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="body1" fontSize="24px" fontWeight="bold">
                Выберите тарифный план и начните пользоваться приложением уже сегодня
            </Typography>

            <Paper elevation={3}
                   sx={{
                       p: 2,
                       borderRadius: 3
                   }}>
                <Stack direction="column" spacing={1}>
                    {androidOptions.map(((o, i, arr) => (
                        <AndroidAppBuyOption key={o.offerCode} {...o}
                                             checked={checkedOption.offerCode === o.offerCode}
                                             onChecked={() => setCheckedOption(o)}
                                             isLast={arr.length - 1 === i}/>
                    )))}

                    <Box mt={3}/>
                    <BuyButton offerCode={checkedOption.offerCode} caption="Продолжить"/>
                </Stack>
            </Paper>
        </Stack>
    );
};

interface BuyBlockProps {
    isAndroid: boolean;
}

const BuyBlock: React.FC<BuyBlockProps> = ({isAndroid}) => {
    if (isAndroid) {
        return (<AndroidDownloadApp/>);
    }

    return (<MonthMenuBuyBlock/>);
};

const MoneyBackGuaranteeBlock: React.FC = () => (
    <Stack direction="column" alignItems="center">
        <Avatar src="/assets/paywall/money.png" variant="square" sx={{width: '60px', height: '60px'}}/>

        <Typography variant="body1" fontWeight="bold" fontSize="18px">
            Гарантированный возврат средств
        </Typography>

        <Typography variant="body1" fontSize="16px">
            Мы надеемся, что составленные меню оправдают ваши ожидания, помогут достичь целей и упростят жизнь.
            Поэтому готовы вернуть средства, если этого не произойдет.
        </Typography>
    </Stack>
);

const PaywallPage: React.FC = () => {
    const accountState = useTypedSelector(s => s.account);
    const navigate = useNavigate();
    const isAndroid = useMemo(() => isAndroidOS(), []);
    const headerPhrase = isAndroid ? 'Приложение настроено под вас' : 'Ваше персональное меню готово';

    useEffect(() => {
        if (!accountState.authToken) {
            navigate(ROUTES.MAIN_PAGE);
        }
    }, [accountState, navigate]);

    return (
        <AppContainer>
            <Stack direction="column" ml={1} mr={1}>
                <Box mt={3}
                     sx={{
                         background: '#70B9BE',
                         borderRadius: 4
                     }}>
                    <Typography color="white"
                                fontSize="32px"
                                fontWeight="bold"
                                ml={1} mr={1} mt={3} mb={3}>
                        {headerPhrase}
                    </Typography>
                </Box>

                <Box mt={3}/>

                <FeaturesList/>

                <Box mt={3}/>
                <Typography variant="h4" textAlign="left" fontWeight="bold">
                    Вы получите
                </Typography>
                <BenefitsList isAndroid={isAndroid}/>

                <Box mt={2}/>
                <BuyBlock isAndroid={isAndroid}/>

                {
                    !isAndroid &&
                    <>
                        <Box mt={3}/>
                        <MoneyBackGuaranteeBlock/>
                    </>
                }
            </Stack>
        </AppContainer>
    );
};

export default PaywallPage;