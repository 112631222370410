export const [bmiMin, bmiMax]: [number, number] = [12, 31.5];

export interface CalcCaloriesAndBmiParams {
    weight: number;
    height: number;
    age: number;
    sex: 'male' | 'female';
    activityLevel: 'verylow' | 'low' | 'mid' | 'high' | 'veryhigh';
    goal: 'drop' | 'maintain' | 'gain';
}

export interface CalcCaloriesAndBmiResult {
    kcal: number;
    bmi: number;
    proteins: number;
    fats: number;
    carbs: number;
}

export const calcCaloriesAndBmi = (
    {
        weight,
        height,
        age,
        sex,
        activityLevel,
        goal
    }: CalcCaloriesAndBmiParams
): CalcCaloriesAndBmiResult => {
    const genderAdditionalValue: number = sex === 'male' ? 5 : -161;
    const dayActivityLevelCoefficients: { [key: string]: number } = {
        'verylow': 1.2,
        'low': 1.375,
        'mid': 1.55,
        'high': 1.725,
        'veryhigh': 1.9
    };

    const goalCoefficients: { [key: string]: number } = {
        'drop': 0.8,
        'maintain': 1,
        'gain': 1.2
    };

    const kcal: number = ((10 * weight) + (6.25 * height) - (5 * age) + genderAdditionalValue) * dayActivityLevelCoefficients[activityLevel] * goalCoefficients[goal];
    const proteins: number = 0.15 * kcal / 4;
    const fats: number = 0.3 * kcal / 9;
    const carbs: number = 0.55 * kcal / 4;
    const heightM: number = height / 100;
    let bmi: number = weight / (heightM * heightM);
    if (bmi > bmiMax) {
        bmi = bmiMax;
    }

    if (bmi < bmiMin) {
        bmi = bmiMin;
    }

    return {
        kcal,
        bmi,
        proteins,
        fats,
        carbs
    };
};

export interface BmiPhraseResult {
    title: string;
    type: 'warning' | 'info';
    description: string;
}

export const getBmiPhrase = (bmi: number): BmiPhraseResult => {
    if (bmi < 18.5) {
        return {
            title: `Ваш ИМТ ${bmi} - ниже нормы`,
            type: 'warning',
            description: 'Нормальным ИМТ считается показатель от 18,5 до 24,9. Приложение учитывает показатель ИМТ для составления меню.'
        }
    }

    if (bmi < 24.5) {
        return {
            title: `Ваш ИМТ ${bmi} - в норме`,
            type: 'info',
            description: 'Ваш ИМТ считается нормальным. Приложение поможет поддерживать ваш показатель на нормальном уровне.'
        }
    }

    return {
        title: `Ваш ИМТ ${bmi} - избыточный вес`,
        type: 'warning',
        description: 'Нормальным ИМТ считается показатель от 18,5 до 24,9. Приложение учитывает показатель ИМТ для составления меню.'
    }
};